import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

const Layout = ({ children, navbarVariant, className }) => {
  return (
    // <div className="relative min-h-screen grid grid-rows-2-footer pt-14 lg:pt-26 text-navy-dark font-neue-normal bg-white">
    //   <Navbar variant={navbarVariant} />
    //   <div className={className}>{children}</div>
    //   <Footer />
    // </div>
    <div className="bg-white">{children}</div>
  );
};

export default Layout;
